import React from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import { useCurrentYear } from '../context/currentYearContext';
import { useExpanded } from '../hooks/useExpanded';
import scrollTo from '../utils/scrollTo';

import TimelineSvg from '../images/svg/timeline.svg';
import CloseSvg from '../images/svg/close.svg';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  color: white;
  z-index: 2;
  height: auto;
  width: 4rem;
  background: black;
  height: ${(props) => !props.expanded && '45px'};
  padding-left: 5px;
  padding-right: 5px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);

  ${up('tablet')} {
    width: 6rem;
    padding: 40px 20px;
    height: initial;
  }
`;

const YearLabel = styled.div`
  width: 100%;
  height: 26px;
  margin-bottom: 2px;
  margin-top: 2px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    background: black;
    z-index: 1;
    padding-left: 1px;
    padding-right: 1px;

    ${up('tablet')} {
      padding-left: 3px;
      padding-right: 3px;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 12.5px;
    height: 1px;
    background: white;
    z-index: 0;
    width: 100%;
  }
`;

const Indicator = styled.div`
  position: absolute;
  top: 0;

  width: 30px;
  height: 30px;
  border-radius: 50%;
  translate: transform 0.2s;
  background: white;
  z-index: 1;
  mix-blend-mode: difference;
  transition: transform 0.3s;
  transform: translate3d(0, ${(props) => props.progress * 100}%, 0);
`;

const Track = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  display: ${(props) => (props.expanded ? 'flex' : 'none')};
  margin-top: 20px;

  ${up('tablet')} {
    margin-top: 0;
    display: flex;
  }
`;

const TimelineButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;

  ${up('tablet')} {
    display: none;
  }

  svg {
    .close-stroke {
      stroke: white;
    }
  }
`;

const Timeline = ({ yearGroups, virtuoso }) => {
  const { currentYear } = useCurrentYear();
  const [expanded, toggleExpanded] = useExpanded(false);
  const years = yearGroups.map((yearGroup) => yearGroup.year);

  const progress = currentYear
    ? yearGroups
        .map((yearGroup) => yearGroup.year)
        .findIndex((year) => year === currentYear)
    : 0;

  return (
    <>
      <Container expanded={expanded}>
        <TimelineButton>
          {expanded && <CloseSvg onClick={toggleExpanded} />}
          {!expanded && <TimelineSvg onClick={toggleExpanded} />}
        </TimelineButton>
        <Track expanded={expanded}>
          {yearGroups.map((yearGroup, index) => {
            return (
              <YearLabel
                key={index}
                onClick={() => {
                  virtuoso.current.scrollToIndex({
                    index: yearGroup.firstItemIndex,
                    align: 'start',
                    behavior: 'smooth',
                  });
                }}
              >
                <span>{yearGroup.year}</span>
              </YearLabel>
            );
          })}
          {/* <Indicator progress={progress} /> */}
        </Track>
      </Container>
    </>
  );
};

export default Timeline;
