import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';

import Menu from '../components/Menu';
import PostList from '../components/PostList';
import CenterText from '../components/common/CenterText';

import { useQuery } from '../context/queryContext';
import { useFilter } from '../context/filterContext';
import { usePosts } from '../context/postsContext';

const Container = styled.div`
  margin-top: ${(props) => props.theme.navigationHeight};
`;

const HomePage = ({
  data: {
    allDatoCmsMisc: { nodes },
  },
  location,
}) => {
  const { posts } = usePosts();
  const { query } = useQuery();
  const [topText, setTopText] = useState(
    location.state && location.state.vorwortNavigate
      ? nodes.find((node) => node.name === 'Vorwort').text
      : false
  );
  const { selectedCategory, selectedSort, selectedAuthor } = useFilter();

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '0% 0% -90% 0%',
  });

  const homeText = nodes.find((node) => node.name === 'Home').text;
  const vorwortText = nodes.find((node) => node.name === 'Vorwort').text;
  const nachwortText = nodes.find((node) => node.name === 'Nachwort').text;

  const onVorwortClick = useCallback(() => {
    setTopText(vorwortText);
    scrollToTop();
  }, [vorwortText]);

  const onNachwortClick = useCallback(() => {
    scrollToBottom();
  }, []);

  if (location.state && location.state.nachwortNavigate) {
    setTimeout(() => {
      scrollToBottom();
    }, 1000);
  }

  return (
    <Container>
      {!query &&
        !selectedAuthor &&
        !selectedCategory &&
        !selectedSort &&
        !topText && (
          <CenterText
            sticky
            hide={inView}
            dangerouslySetInnerHTML={{ __html: homeText }}
          />
        )}
      {topText && !query && (
        <CenterText>
          <h1>Vorwort</h1>
          <div dangerouslySetInnerHTML={{ __html: topText }} />
        </CenterText>
      )}
      <Menu
        onNachwortClick={onNachwortClick}
        onVorwortClick={onVorwortClick}
        setTopText={setTopText}
        hasScrolled={inView}
      />
      <div ref={ref}>
        <PostList />
      </div>
      {posts.length > 0 && (
        <CenterText>
          <h1>Nachwort</h1>
          <div dangerouslySetInnerHTML={{ __html: nachwortText }} />
        </CenterText>
      )}
    </Container>
  );
};

function scrollToTop() {
  typeof window !== 'undefined' &&
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
}

function scrollToBottom(isSmooth = 'smooth') {
  typeof window !== 'undefined' &&
    window.scrollTo({
      top: document.body.scrollHeight,
      left: 0,
      behavior: isSmooth,
    });
}

export const query = graphql`
  {
    allDatoCmsMisc(filter: { name: { regex: "/Home|Vorwort|Nachwort/" } }) {
      nodes {
        name
        text
      }
    }
  }
`;

export default HomePage;
