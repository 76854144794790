import React, { useRef } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import Post from './post/Post';
import YearGroup from './post/YearGroup';
import Timeline from './Timeline';
import CenterText from './common/CenterText';

import { usePosts } from '../context/postsContext';
import { useFilter } from '../context/filterContext';
import { useCurrentYear } from '../context/currentYearContext';

import { Virtuoso, GroupedVirtuoso } from 'react-virtuoso';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${(props) => props.minHeight && '600px'};
  position: relative;

  ${up('tablet')} {
    padding-left: 3.2rem;
    padding-right: 12.2rem;
  }

  [data-item-group-index] {
    position: relative;
  }
`;

const EmptyResults = styled(CenterText)`
  margin-top: 20rem;

  ${up('tablet')} {
    margin-top: 25rem;
  }
`;

const LineContainer = styled.div`
  position: fixed;
  top: 0;
  padding-left: 12rem;

  ${up('tablet')} {
    padding-left: 12.7rem;
  }
`;

const Line = styled.div`
  width: 2px;
  height: 1200px;
  background-color: white;

  ${up('tablet')} {
  }
`;

const YearMarker = styled.div`
  width: 1px;
  height: 1px;
  opacity: 0;
  visibility: hidden;
`;

const PostList = () => {
  const { posts } = usePosts();
  const { selectedSort } = useFilter();
  const { setCurrentYear } = useCurrentYear();
  const virtuoso = useRef();

  if (posts.length === 0) {
    return (
      <EmptyResults>
        Es sind keine Einträge mit diesen Filtern zu finden.
      </EmptyResults>
    );
  }

  if (selectedSort === 'chronological') {
    let count = 0;

    let yearGroups = posts
      .reduce((accumulator, current) => {
        const year = getYear(current.date);

        if (!accumulator.find((group) => group.year === year)) {
          accumulator.push({
            year,
            posts: [],
          });
        }

        accumulator.find((group) => group.year === year).posts.push(current);

        return accumulator;
      }, [])
      .map((yearGroup) => {
        let currentCount = count;
        count = count + yearGroup.posts.length;
        return {
          ...yearGroup,
          firstItemIndex: currentCount,
        };
      });

    return (
      <>
        <Timeline yearGroups={yearGroups} virtuoso={virtuoso} />
        <Container>
          <LineContainer>
            <Line />
          </LineContainer>
          <GroupedVirtuoso
            useWindowScroll
            ref={virtuoso}
            groupCounts={yearGroups.map((yearGroup) => yearGroup.posts.length)}
            // rangeChanged={({ startIndex, endIndex }) => {
            //   const startYear = getYear(posts[startIndex].date);
            //   const endYear = getYear(posts[endIndex].date);

            //   if (startYear === endYear) {
            //     setCurrentYear(startYear);
            //   } else if (startYear !== endYear) {
            //     if (Math.abs(startYear - endYear) === 1) {
            //       setCurrentYear(endYear);
            //     } else {
            //       setCurrentYear(endYear - 1);
            //     }
            //   }
            // }}
            groupContent={(index) => {
              return <YearMarker />;
            }}
            itemContent={(index) => (
              <Post
                largePaddingBottom
                rightHandSide={index % 2 === 1}
                key={posts[index].originalId}
                {...posts[index]}
              />
            )}
          />
        </Container>
      </>
    );
  }

  return (
    <Container minHeight>
      <Virtuoso
        useWindowScroll
        totalCount={posts.length}
        itemContent={(index) => (
          <Post
            rightHandSide={index % 2 === 1}
            key={posts[index].originalId}
            {...posts[index]}
          />
        )}
      />
    </Container>
  );
};

function getYear(dateString) {
  if (!dateString) {
    return 'u.d.';
  }

  return new Date(dateString).getFullYear();
}

export default PostList;
