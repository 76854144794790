function scrollTo(selector, blockPosition = 'start') {
  const element = document.querySelector(selector);

  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: blockPosition,
    });

    return true;
  }
  return false;
}

export default scrollTo;
