import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import { useCurrentYear } from '../../context/currentYearContext';

const Container = styled.div`
  /* padding-top: 5rem; */
`;

const YearGroup = ({ children, year }) => {
  const { setCurrentYear } = useCurrentYear();

  const { ref, inView, entry } = useInView({
    rootMargin: '-25% 0% -70% 0%',
  });

  useEffect(() => {
    if (inView) {
      setCurrentYear(year);
    }
  }, [setCurrentYear, inView, year]);

  return <Container ref={ref}>{children}</Container>;
};

export default YearGroup;
